import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  moveElementToBody,
  removeElementFromBody,
} from '../../hooks/move-element-to-body';
import { Modal } from './modal';

export enum ModalTrigger {
  once,
  exitIntent,
  initiallyOpened,
}

type Props =
  | {
      trigger: ModalTrigger.once;
      modalName: string;
      size?: 'small';
    }
  | {
      trigger?: ModalTrigger.exitIntent | ModalTrigger.initiallyOpened;
      size?: 'small';
    };

export const useModal = (props: Props) => {
  const parent = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLElement | null>(null);
  const [showModal, setShowModal] = useState(
    props.trigger === ModalTrigger.initiallyOpened,
  );

  const moveModalToBody = useCallback(() => {
    if (parent.current !== null && parent.current.children[0]) {
      ref.current = parent.current.children[0] as HTMLElement;
      moveElementToBody(ref.current, parent.current);
    }
  }, []);

  useEffect(() => {
    if (props.trigger === ModalTrigger.once) {
      if (!localStorage.getItem(props.modalName)) {
        localStorage.setItem(props.modalName, 'true');
        setShowModal(true);
      }
    }
  }, [props]);

  const removeModalFromBody = () => {
    if (
      ref.current !== null &&
      document.querySelector('#gatsby-focus-wrapper') === ref.current.parentNode
    ) {
      removeElementFromBody(ref.current);
    }
  };

  useEffect(() => {
    moveModalToBody();
  }, [moveModalToBody, showModal]);

  const open = () => {
    setShowModal(true);
  };

  const close = useCallback(() => {
    removeModalFromBody();
    setShowModal(false);
  }, []);

  const ModalComponent = useMemo(
    () =>
      ({ children }: PropsWithChildren) => (
        <Modal show={showModal} onClose={close} ref={parent} size={props.size}>
          {children}
        </Modal>
      ),
    [showModal, close, props],
  );

  return {
    ModalComponent,
    open,
    close,
  };
};
